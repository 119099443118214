.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;

  >label {
    align-self: flex-start;
  }

  .errorsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .imageInput {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 10px 20px;
    width: 100%;
    height: 130px;
    background-color: rgba(0, 84, 166, 0.05);
    border-radius: 10px;
    border: 1px dashed #007DC5;

    &__uploadButton {
      margin-bottom: 12px;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__uploadText {
      color: #0054a6;
      font-weight: 500;
      margin-bottom: 12px;
    }

    &__sizesInfoText {
      color: #6D6E70;
      font-size: 12px;
    }

    &__selectedImagePreview {
      display: flex;
      align-items: center;
      justify-content: center;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;

        &__buttons {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-flow: column;

          .changeBtn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-transform: uppercase;
            padding: 5px 15px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            color: #0054a6;
            margin: 4px;
            width: 100%;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          .removeBtn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-transform: uppercase;
            padding: 5px 15px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            color: #B71816;
            margin: 4px;
            width: 100%;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}