.scheduleLockReleaseSelects {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column;
  padding: 8px;

  &__filterDateRangePicker {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    padding: 8px 0;
    width: 100%;

    > span {
      font-size: 18px;
      font-weight: 500;
    }

    > p {
      font-size: 11px;
    }

    .datePickerTrigger {
      align-self: center;
      border: 1px solid #ddd;
      background: #fff;
      width: 100%;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: background 200ms ease-in-out;

      &:hover {
        background: lighten($color: #ddd, $amount: 5%);
      }
    }
  }

  &__filterActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .clearBtn {
      align-self: center;
      border: 1px solid #ddd;
      background: #fff;
      width: 120px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: background 200ms ease-in-out;

      &:hover {
        background: lighten($color: #ddd, $amount: 5%);
      }
    }

    .filterBtn {
      align-self: center;
      background: #0054a6;
      color: #fff;
      width: 120px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: background 200ms ease-in-out;

      &:hover {
        background: lighten($color: #0054a6, $amount: 5%);
      }
    }


  }

  .backBtn {
    align-self: center;
    border: 1px solid #ddd;
    background: #fff;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    color: #444;
    cursor: pointer;
    transition: background 200ms ease-in-out;
    margin: 8px 8px 0 8px;

    &:hover {
      background: lighten($color: #ddd, $amount: 5%);
    }
  }
}
