.sideBar {
    width: 100%;
    padding: 8px 8px 0 8px;

    &__label {
        margin-left: 5px;
        font-size: 12px;
    }

    .backBtn {
        align-self: center;
        border: 1px solid #ddd;
        background: #fff;
        width: 100%;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        transition: background 200ms ease-in-out;

        &:hover {
            background: lighten($color: #ddd, $amount: 5%);
        }
    }
}
