.citySelect {
    width: 100%;
    height: 100%;
    padding: 4px 0px;

    #citySelectDropDownBox {
        width: 100%;
    }

    &__label {
        margin-left: 5px;
        font-size: 12px;
    }

    &__field {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        > button {
            height: 36px;
            width: 35px;

            margin-left: 4px;
            align-self: flex-end;
            border: 1px solid #0054a6;
            background-color: #fff;
            color: #0054a6;
            border-radius: 4px;
            cursor: pointer;
            transition: background 200ms ease-in-out;

            &:hover {
                background: rgba(#0054a6, 0.2);
            }
        }
    }
}
