.optInModalOverlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 99999;

    .optInModal {
        border-radius: 5px;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        box-shadow: 4px 6px 6px 2px rgba(0, 0, 0, 0.8);
        width: 700px;
        height: 75%;

        &__header {
            background-color: #0054a5;
            border-bottom: 3px solid #ed1d24;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            font-size: 20px;
            width: 100%;
            height: 52px;
            margin: 0 -8px;
            line-break: none;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: bold;
            padding: 12px;
            margin-bottom: 10px;
            flex: 1 0 52px;

            > img {
                width: 30%;
            }
        }

        &__content {
            width: 100%;
            margin-bottom: 5px;
            overflow-y: auto;
            padding: 0 20px;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.5;
        }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            button:disabled,
            button[disabled] {
                border: 1px solid #ccc;
                background: #ddd;
                text-shadow: rgba(0, 0, 0, 0.1) -1px 0, rgba(0, 0, 0, 0.1) 0 -1px, rgba(255, 255, 255, 0.1) 1px 0,
                    rgba(255, 255, 255, 0.1) 0 1px, rgba(0, 0, 0, 0.1) -1px -1px, rgba(255, 255, 255, 0.1) 1px 1px;
                color: #555;

                &:hover {
                    background: lighten(#ddd, 4%);
                }
            }

            .closeModalBtn {
                margin: 10px auto;
                width: 220px;
                background: #0054a6;
                color: #fff;
                padding: 10px 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                cursor: pointer;
                transition: background 200ms ease-in-out;

                &:hover {
                    background: lighten($color: #0054a6, $amount: 5%);
                }
            }
        }
    }
}
