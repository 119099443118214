.datePickerTrigger {
  align-self: center;
  border: 1px solid #ddd;
  background: #fff;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 200ms ease-in-out;

  &:hover {
    background: lighten($color: #ddd, $amount: 5%);
  }
}