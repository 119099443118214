.schedulePeriod {
    overflow: hidden;
    border-radius: 5px;
    border-left: 5px solid #0054a6;
    border-top: 1px solid #ddd;
    -webkit-box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.46);
    box-shadow: 0px 4px 8px -3px rgba(0, 0, 0, 0.46);

    &.cantRemove {
        border-left: none;
        box-shadow: none;
        border: 1px solid #ddd;
        background: #fafafa;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        justify-content: space-between;

        &__periodName {
            font-size: 14px;
            font-weight: 500;
        }

        &__removeBtn {
            outline: none;
            cursor: pointer;
            background: transparent;
            display: flex;
            align-items: center;
            padding: 2px;
            justify-content: center;

            &:hover {
                border-radius: 50%;
                background: #f2f2f2;
            }

            > svg {
                width: 18px;
                height: 18px;
                fill: #ed1d24;
            }
        }

        &__cantRemoveBtn {
            outline: none;
            cursor: auto !important;;
            background: transparent;
            display: flex;
            align-items: center;
            padding: 2px;
            justify-content: center;

            &:hover {
                border-radius: 50%;
                background: #f2f2f2;
            }

            > svg {
                width: 18px;
                height: 18px;
                fill: #9d9d9d;
            }
        }
    }

    &__timeSelection {
        width: 100%;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__startTime,
        &__endTime {
            align-self: center;
            border: 1px solid #ddd;
            background: #fff;
            width: 95px;
            padding: 8px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            border-radius: 5px;
            cursor: pointer;
            transition: background 200ms ease-in-out;

            &:hover {
                background: lighten($color: #ddd, $amount: 5%);
            }
        }
    }
}
