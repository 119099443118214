.servicesModalOverlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #fff, $alpha: 0.7);
    z-index: 99999;

    .servicesModal {
        border-radius: 5px;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        box-shadow: 4px 6px 6px 2px rgba(0, 0, 0, 0.6);
        width: 300px;
        height: auto;

        &__clientName {
            background-color: rgb(239, 242, 247);
            font-size: 18px;
            width: 100%;
            margin: 0 -8px;
            line-break: none;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: bold;
            padding: 8px;
            margin-bottom: 10px;
        }

        &__serviceIcons {
            width: 100%;
            margin-bottom: 10px;
            max-height: 320px;
            overflow-y: auto;

            &__service {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 5px 20px;
                transition: all ease-in 0.3s;

                &:hover {
                    filter: brightness(150%);
                    color: #0054a6;
                }

                &__icon {
                    > img {
                        width: 38px;
                        height: 38px;
                    }

                    margin-right: 15px;
                }

                &__name {
                    font-size: 16px;
                    font-weight: bold;
                    text-align: left;
                }
            }
        }

        .closeModalBtn {
            margin: 10px auto;
            width: 94%;
            align-self: center;
            background: #0054a6;
            color: #fff;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            transition: background 200ms ease-in-out;

            &:hover {
                background: lighten($color: #0054a6, $amount: 5%);
            }
        }
    }
}
