.weekView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 8px;
}

.timeSelection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__startTime,
  &__endTime {
    align-self: center;
    border: 1px solid #ddd;
    background: #fff;
    width: 120px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background 200ms ease-in-out;

    &:hover {
      background: lighten($color: #ddd, $amount: 5%);
    }
  }
}
