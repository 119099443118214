.ant-steps-item-finish {
	> .ant-steps-item-container {
		> .ant-steps-item-content {
			> .ant-steps-item-title {
				color: #0054a6;
				&::after {
					background: #0054a6;
				}
			}
		}
	}
  .ant-steps-item-icon {
    background: #0054a6;
    border-color: #0054a6;

    > .ant-steps-icon {
      display: inline-block;
      vertical-align: text-top;
    }
  }
}

.ant-steps-item-wait {
	.ant-steps-item-icon {
		background-color: #D7E2ED;
		color: #7B8FA5;
		border: 0;
	}
}

.anticon {
	&.anticon-check {
		&.ant-steps-finish-icon{
			color: #fff;
		}
	}
}

.ant-steps-item-process {
	> .ant-steps-item-container {
		> .ant-steps-item-icon {
			background: #0054a6;
		}
		> .ant-steps-item-content {
			> .ant-steps-item-title {
				color: #0054a6;
			}
		}
	}
  .ant-steps-item-icon {
		background: #0054a6;
    border: 0;
  }
}