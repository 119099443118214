.partnerUserCard {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;

    &__userImg {
        background: url("../../../images/icons/icon-user.svg") no-repeat center;
        width: 42px;
        height: 42px;
        flex: 1 0 42px;
    }

    &__userInfo {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-flow: column;
        flex: 2;
        margin: 0 8px;

        &__userName {
            font-weight: 500;
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 165px;
            display: inline-block;
            text-transform: uppercase;
        }

        &__userEmail {
            font-size: 11px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 165px;
            display: inline-block;
            margin-top: -2px;
        }

        &__description {
            color: #666;
            font-size: 12px;
            font-weight: 500;
        }
    }
}
