.servicesGrid {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    &__exportButtons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .exportBtn {
            margin: 8px;
            width: 135px;
        }
    }

    .servicesGridCell {
        display: flex;
        align-items: center;
        height: 34px;
    }

    .dx-datagrid-header-panel {
        padding: 0 8px !important;
    }
}
