.scheduleDetailsModalOverlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color: #fff, $alpha: 0.7);
    z-index: 2000;

    .scheduleDetailsModal {
        border-radius: 5px;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        box-shadow: 4px 6px 6px 2px rgba(0, 0, 0, 0.6);
        width: 35%;
        height: 70%;

        @media (max-width: 961px) {
            width: 95%;
        }

        @media (max-width: 450px) {
            width: 95%;
        }

        &__header {
            background-color: rgb(239, 242, 247);
            font-size: 18px;
            width: 100%;
            margin: 0 -8px;
            line-break: none;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: bold;
            padding: 8px;
            margin-bottom: 10px;

            > span {
                color: #0054a6 !important;
            }
        }

        &__content {
            height: 100%;
            width: 100%;
            padding: 8px 12px;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            &__actionButtons {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-wrap: wrap;
                height: auto;

                .actionBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    font-size: 12px;
                    border: 1px solid #0054a6;
                    background-color: #fff;
                    color: #0054a6;
                    border-radius: 4px;
                    padding: 4px 0;
                    cursor: pointer;
                    transition: background 200ms ease-in-out;

                    &:nth-child(2) {
                        margin: 0 5px;
                    }

                    &:hover {
                        background: rgba(#0054a6, 0.2);
                    }
                }

                .cancelBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    font-size: 12px;
                    border: 1px solid red;
                    background-color: #fff;
                    color: red;
                    border-radius: 4px;
                    padding: 4px 0;
                    cursor: pointer;
                    transition: background 200ms ease-in-out;

                    &:hover {
                        background: rgba(red, 0.2);
                    }
                }
            }

            table {
                width: 100%;
            }

            &__services {
                max-height: 110px;
                overflow-y: auto;
                border: 1px solid #ddd;
                padding: 8px;
                border-radius: 5px;

                > legend {
                    color: #aaa;
                    font-size: 14px;
                    width: auto;
                    margin: 0 5px;
                }

                > table {
                    margin-top: -5px;
                }
            }

            &__totalValue {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                > span {
                    font-size: 16px;
                    font-weight: bold;
                }

                > div {
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .closeModalBtn {
            margin: 10px auto;
            width: 94%;
            align-self: center;
            background: #0054a6;
            color: #fff;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;
            transition: background 200ms ease-in-out;

            &:hover {
                background: lighten($color: #0054a6, $amount: 5%);
            }
        }
    }
}
